import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import "./Header.css";
export default function Header() {
  return (
    <header className="header ">
      <Navbar variant="light" expand="lg">
        <div className="container nav-contain">
          <Navbar.Brand href="/" title="Neelgai" className="title-neelgai ">
            <>Neelgai</>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-2">
            <GiHamburgerMenu color="#35334f" className="hamburger" />
            <ImCross
              color="#35334f"
              className="cross"
              style={{ height: "0.7rem" }}
            />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end flex-grow-1 me-2 me-lg-0 d-flex">
              <Link
                to="/"
                className=" ms-0 ms-lg-5 mt-4 my-lg-auto nav-custom-edits"
                title="Home"
              >
                <>Home</>
              </Link>
              <Link
                to="/about"
                className=" ms-0 ms-lg-5 mt-4 my-lg-auto nav-custom-edits"
                title="About Us"
              >
                <>About</>
              </Link>

              <Link
                to="/services"
                className="ms-0 ms-lg-5 mt-4 my-lg-auto  nav-custom-edits"
                title="services"
              >
                <>Services </>
              </Link>
              <Link
                to="/bootcamp"
                className="ms-0 ms-lg-5 mt-4 my-lg-auto  nav-custom-edits"
                title="Bootcamp"
              >
                <>Bootcamp </>
              </Link>
              {/* <Link
                to="/blogs"
                className="ms-0 ms-lg-5 mt-4 my-lg-auto  nav-custom-edits"
                title="Blog"
              >
                <>Blog </>
              </Link> */}
              <Link
                to="/hiring"
                className=" ms-0 ms-lg-5 mt-4  my-lg-auto  nav-custom-edits"
                title="Hiring"
              >
                <>Hiring</>
              </Link>
              <Link
                to="/contact"
                className="ms-0 ms-lg-5 mt-4 my-lg-auto  nav-button-edits "
                title="Book a call"
              >
                <>Book a call</>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </div>

        {/* </Container> */}
      </Navbar>
      {/* <MessengerComponent pageId={HelperFunction.pageId}/> */}
    </header>
  );
}
