import React from "react";
import Images from "../../Constant/Images";
import "./Testimonials.css";

export default function Testimonials() {
  const client = [
    {
      id: 2,
      color: " #344a40",
      description:
        "We've been very pleased with our experience working with Neelgai. Their team was incredibly helpful in helping us find the perfect developer for our project. The work was stellar, the communication was excellent and we couldn't have been happier with the overall experience. We're excited to work with Neelgai for more projects in the future!.",
      client_name: "Puru Shah (Senior Engineer at Tesla)",
    },
    {
      id: 3,
      color: " #472711",
      description:
        "They are a dynamic force, consistently seeking and incorporating our feedback to ensure a stellar outcome. Their dedication is evident in helping us achieve an impressive 99.99% SLA, demonstrating a commitment to excellence and reliability. Neelgai's collaborative approach and responsiveness make them invaluable partners in achieving and surpassing service level objectives. Kudos to Neelgai's consultants for their unwavering commitment to our success!.",
      client_name: "Marlyne Hakizimana (Currently an SWE at Nvidia)",
    },
    {
      id: 4,
      color: " #252d42d9",
      description:
        "Neelgai stands out not just for their exceptional consultants but also for their reasonable pricing. The value they deliver is outstanding, with consultants who consistently produce top-quality work. Neelgai's combination of affordability and excellence makes them a standout choice, proving that high-caliber service doesn't have to come with a hefty price tag. Cheers to Neelgai for redefining expectations and making quality expertise accessible to all!",
      client_name: "Wesley (CEO at an Stealth Mode Startup)",
    },
  ];
  const firstClient = client[0];
  return (
    <div className="pb-5">
      <h1 className="mt-5 pt-4">Testimonials</h1>
      <section className="section testimonials section-5">
        <div className="container  d-flex flex-column justify-content-center">
          <div className="row pb-5">
            <div className="col-12 d-flex justify-content-center">
              <h3 className="fs-1 fw-bold text-center text-primary pb-3"></h3>
            </div>
          </div>
          <div className="row pb-5 mb-3">
            <div className="col-12 col-xl-5 d-flex flex-column">
              <img
                src={Images.testimonial_awards}
                className=" img-fluid w-0 "
              />
            </div>
            <div className="col-12 col-xl-7">
              <div className="pb-4 text-start">
                <img
                  src={Images.testimonial_left}
                  className=" img-fluid  "
                  width={63}
                />
              </div>
              <div className="card d-flex border-0 shadow p-5">
                <div
                  className=" rounded-circle size-90 me-4 mb-3"
                  style={{ backgroundColor: "#344a40" }}
                ></div>
                <p className="card-text text-black-61 text-start">
                  "Deepak and Arbind worked wonders revamping our old website,
                  turning it into a modern infrastructure marvel. Their
                  expertise and attention to detail shine through, creating a
                  seamless, visually appealing experience. They not only met but
                  exceeded modern standards, showcasing a deep understanding of
                  the latest technologies. Neelgai's transformative touch has
                  elevated our online presence, making them strategic partners
                  dedicated to digital success. Kudos to Neelgai for breathing
                  new life into our web platform!."
                  <br />
                  <span className="fw-bold  text-wrap">
                    - Ojaswi Pandey (Data Scientist at Defense Health Agency)
                  </span>
                </p>
              </div>
              <div className="pt-4 text-end">
                <img
                  src={Images.testimonial_right}
                  className=" img-fluid  "
                  width={63}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {client.map((elem, index) => (
              <div key={index} className="col-12 col-xl-4 mb-4">
                <div className="card border-0 shadow border-radius-34 p-4">
                  <div className="card-body">
                    <p className="card-text text-black-61">
                      <span className="fw-bold text-primary text-nowrap"></span>
                    </p>
                  </div>

                  <div className="card-body">
                    <div
                      className=" rounded-circle size-90 mb-4"
                      style={{ backgroundColor: elem.color }}
                    ></div>
                    <p className="card-text text-black-61 text-start">
                      "{elem.description}"
                      <br />
                      <span className="fw-bold  text-wrap">
                        - {elem.client_name}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
