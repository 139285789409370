import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Header, Footer } from "../../Common";

export default function BlogDetail() {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchBlogDetails() {
      try {
        const response = await axios.get(
          `http://localhost:4000/api/blogs/${slug}`
        );
        const blogData = response.data;
        const blogWithAbsoluteImagePath = {
          ...blogData,
          image: `http://localhost:4000/${blogData.image}`,
        };
        setBlog(blogWithAbsoluteImagePath);
      } catch (error) {
        console.error("Error fetching blog details:", error);
        setError("Error fetching blog details. Please try again later.");
      } finally {
        setLoading(false);
      }
    }
    fetchBlogDetails();
  }, [slug]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div>
      <Header />
      <div className="container mt-5 pt-5">
        <h2>{blog.name}</h2>
        <img src={blog.image} alt="image-blog" className="img-fluid" />
        <div
          className="text-start skill-more"
          dangerouslySetInnerHTML={{ __html: blog.description }}
        ></div>
      </div>
      <Footer />
    </div>
  );
}
