import React, { useState, useEffect } from "react";
import "./HiringDetail.css";
import { useParams } from "react-router-dom";
import { Header, Footer } from "../../Common";
import { job } from "../../Constant/job";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

export default function HiringDetail() {
  const { slug } = useParams();
  const [jobs, setJob] = useState(null);

  useEffect(() => {
    const selectedJob = job.find((p) => p.slug === slug);
    if (!selectedJob) {
      console.error("No job found with the provided id:", slug);
    }
    setJob(selectedJob);
  }, [slug]);

  return (
    <div className="hire-detail">
      <Header />
      <div className="bg-detail container">
        <h4 className="pt-5">Neelgai</h4>
        {jobs ? (
          <div>
            <h1>{jobs.job_title}</h1>
            <p>
              {jobs.city}, posted on {jobs.post_date}
            </p>
            <div className="  more-hire text">
              <Link to="https://neelgai.zohorecruit.com/forms/283fb6777ff26df872dc7bc7cf285a9e982f42f521079726f426f3e20822f8b7">
                <button className="btn btn-success btn-more ">
                  I'm interested
                </button>
              </Link>
            </div>
            <div className="  d-flex justify-content-center">
              <div className="col-md-10 text-start jb-list">
                <Link to="/hiring ">
                  <span className="mb-2">Job listing</span>
                </Link>
                <span className="jb-detail ">
                  <MdKeyboardArrowRight size={24} />
                  Job details
                </span>
                <div className="bor-hire mt-2"></div>
                <div className="row  pt-2">
                  <div className="col-md-9">
                    <h5 className="job-dest">Job Description</h5>
                    <p
                      className="job-child"
                      dangerouslySetInnerHTML={{ __html: jobs.job_desc }}
                    ></p>
                    <h5 className="jb-req py-4">Responsibilities</h5>
                    {jobs.responsibilities.map((elem, index) => (
                      <div key={index}>
                        <ul>
                          <li>{elem.name}</li>
                        </ul>
                      </div>
                    ))}
                    {jobs.requirements && jobs.requirements.length > 0 && (
                      <>
                        <h5 className="job-require py-4">Requirements</h5>
                        {jobs.requirements.map((elem, index) => (
                          <div key={index}>
                            <ul>
                              <li>{elem.name}</li>
                            </ul>
                          </div>
                        ))}
                      </>
                    )}
                    {jobs.qualification && jobs.qualification.length > 0 && (
                      <>
                        <h5 className="py-4">Preferred Qualifications</h5>
                        {jobs.qualification.map((elem, index) => (
                          <div key={index}>
                            <ul>
                              <li>{elem.name}</li>
                            </ul>
                          </div>
                        ))}
                      </>
                    )}
                    {jobs.not_required && jobs.not_required.length > 0 && (
                      <>
                        <h6 className="py-5">Not Required but Great to have</h6>
                        {jobs.not_required.map((elem, index) => (
                          <div key={index}>
                            <ul>
                              <li>{elem.name}</li>
                            </ul>
                          </div>
                        ))}
                      </>
                    )}

                    <h5 className="py-4 job-require">Benefits</h5>
                    {jobs.benefits.map((elem, index) => (
                      <div key={index}>
                        <ul>
                          <li>{elem.name}</li>
                        </ul>
                      </div>
                    ))}

                    {jobs.duration && jobs.duration.length > 0 && (
                      <>
                        <span className="py-4 job-require">Duration</span>:{" "}
                        {jobs.duration}
                      </>
                    )}
                    <div className="  more-hire text-start py-4">
                      <Link to="https://neelgai.zohorecruit.com/forms/283fb6777ff26df872dc7bc7cf285a9e982f42f521079726f426f3e20822f8b7">
                        <button className="btn btn-success btn-more ">
                          I'm interested
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3 bor-left">
                    <h5 className="job-dest">Job Information</h5>
                    <div className="jb-ind pt-2">Industry</div>
                    <div className="jb-small">{jobs.industry}</div>
                    <div className="jb-ind pt-3">Work Experience</div>
                    <div className="jb-small">{jobs.Experience}</div>
                    <div className="jb-ind pt-3">City</div>
                    <div className="jb-small">{jobs.city}</div>
                    <div className="jb-ind pt-3">Country</div>
                    <div className="jb-small">{jobs.country}</div>
                    {jobs.start_date && jobs.start_date.length > 0 && (
                      <>
                        <div className="jb-ind pt-3">Start Date</div>
                        <div className="jb-small">{jobs.start_date}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>

      <Footer />
    </div>
  );
}
